<template>
  <el-dialog
    :title="title"
    top="45vh"
    :visible="visible"
    @open="openDialog"
    @close="closeDialog"
    @closed="initData"
    :modal-append-to-body="false"
    center
  >
    <div>
      <!-- 头部 -->
      <!-- <mini-header title="兑换订单"></mini-header> -->
      <!-- 订单信息 -->
      <div class="order-wrapper" v-loading="loading">
        <div class="wrap" v-if="timeRemaining">
          <div class="top">
            <div class="order-info">
              <!-- <div class="left">
                <div class="info-item" v-if="timeRemaining">
                  <span>请在</span>
                  <span class="duration">{{ timeRemaining }}</span>
                  <span>内完成兑换, 超时后将自动取消订单</span>
                </div>
                <div
                  class="info-item address elip"
                  :class="{ hide: showDetails }"
                ></div>
              </div>
              <div class="right" v-if="timeRemaining">
                <div class="pay-amount">
                  <span>应付金额：</span>
                  <span class="amount">{{ order.payAmount }}</span>
                  <span class="unit">{{ $currency }}</span>
                </div>
              </div> -->
            </div>
          </div>
          <div class="info-item">
            <span>请在</span>
            <span class="duration">{{ timeRemaining }}</span>
            <span>内完成兑换, 超时后将自动取消订单</span>
          </div>
          <div class="pay-amount">
            <span>应付云淘豆：</span>
            <span class="amount" v-if="order">{{
              parseFloat(order.payAmount).toFixed(2)
            }}</span>
            <span class="unit">{{ $currency }}</span>
          </div>
        </div>
        <el-empty v-else v-loading="true" :image-size="50"></el-empty>
        <!-- <el-button type="primary" size="medium" round @click="pay"
          >云淘豆付款</el-button
        > -->
      </div>

      <!-- 选择支付方式 -->
      <div class="payment-wrapper" v-if="showPayType">
        <div class="wrap">
          <div class="tip">选择以下支付方式付款</div>
          <div class="pay-platform">
            <div class="title">支付平台</div>
            <div class="platform-wrap">
              <img
                class="platform"
                src="@/assets/imgs/pay/alipay.png"
                mode="aspectFit"
                @click="requestPayment(0)"
              />
              <img
                class="platform"
                src="@/assets/imgs/pay/wechatpay.png"
                mode="aspectFit"
                @click="requestPayment(1)"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 支付弹窗 -->
      <zw-dialog
        :hidden="hiddenQrcode"
        :title="payMode == 0 ? '支付宝兑换' : '微信兑换'"
        :width="370"
        :height="400"
        @close="cancelPayment"
      >
        <div class="qrcode-wrapper">
          <div class="qrcode-wrap">
            <img
              class="qrcode"
              :src="$target + `/order/pay/qrcode?content=${qrcodeUrl}`"
              mode="aspectFit"
            />
            <div class="logo-wrap">
              <img class="logo" :src="payLogo" mode="platform" />
            </div>
          </div>
          <div class="tips">
            <div>
              请使用<span class="platform">{{
                payMode == 0 ? "支付宝" : "微信"
              }}</span
              >扫描
            </div>
            <div>二维码完成兑换</div>
          </div>
        </div>
      </zw-dialog>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-tooltip
        class="item"
        effect="dark"
        content="取消后可在个人中心->订单中心->商品订单查找该笔订单"
        placement="bottom-end"
      >
        <el-button @click="closeDialog">取消兑换</el-button>
      </el-tooltip>

      <el-button v-if="order" type="primary" @click="pay">云淘豆付款</el-button>
    </span>
  </el-dialog>
</template>

<script>
// import miniHeader from "@/components/common/mini-header.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
    //关闭弹窗是否返回上一页
    routerReturn: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    // miniHeader,
  },
  data() {
    return {
      timeRemaining: "",
      order: {},
      showDetails: false,
      qrcodeUrl: "",
      payMode: 0,
      hiddenQrcode: true,
      orderNo: "",
      payLogo: "",
      loading: false,
      title: "订单兑换",
      showPayType: false,
      closeLink: true,
    };
  },
  watch: {
    order(e) {
      if (e) {
        if (e.endTime) {
          this.startCountdown();
        }
      }
    },
    orderNo(e) {
      if (e) {
        this.findBaseDetails();
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    // clearInterval(this.timer)
    // this.orderNo = 0;
    next();
  },

  computed: {
    ...mapGetters(["getOrderInfo"]),
  },
  methods: {
    ...mapActions(["setOrderInfo", "removeOrder"]),
    openDialog() {
      this.orderNo = this.id;
    },
    closeDialog() {
      clearInterval(this.timer);
      this.$emit("update:visible", false);
      this.$emit("delete");
      if ( this.closeLink) {
        this.linkRouter();
      }
    },
    linkRouter() {
      if (this.routerReturn) {
        this.$router.push({ path: "/order/index" });
      }
      //  
    },
    initData() {
      this.order = {};
      this.orderNo = "";
      this.title = "订单兑换";
      this.closeLink=true
    },
    async pay() {
      this.loading = true;
      const list = this.orderNo.split(",");
      var order = await this.SendOrderPayApi(list);
      if (order.length > 0) {
        this.loading = false;
        this.closeLink = false;
        var alterText = "订单兑换成功";
        if (order.length != list.length) {
          alterText = "存在未兑换成功订单，请前往订单中心查看";
        }
        //var errorOrder=""
        this.closeDialog();
        this.$alert(alterText, "", {
          confirmButtonText: "查看详情",
          closeOnClickModal: true,
          callback: (action, instance) => {
            console.log(action);
            if (action == "confirm") {
              if (list.length > 1) {
                this.$router.replace({
                  path: "/order/index",
                });
              } else {
                this.$router.replace({
                  path: "/order/detail?orderNo=" + order[0].Id,
                });
              }
            } else {
              this.linkRouter();
            }
            clearInterval(this.timer);
          },
        });
      } else {
        this.loading = false;
        this.closeDialog();
      }
    },
    async SendOrderPayApi(list) {
      var order = [];
      for (var item of list) {
        await this.$axios
          .put("/api/GoodsOrder/OrderPay", this.qs.stringify({ OrderID: item }))
          .then((res) => {
            if (res.data) {
              order.push(res.data);
            }
          });
      }
      return order;
    },
    findBaseDetails() {
      this.loading = true;
      this.order = this.$store.getters.getOrderInfo(this.orderNo);
      if (!this.order) {
        this.$axios
          .get("/api/GoodsOrder/OrderDetail", {
            params: {
              UserID: this.$store.getters.getUserId,
              OrderID: this.orderNo,
            },
          })
          .then((res) => {
            if (res.data) {
              const data = res.data[0];
              const goodsAmount = data.GoodsMonry;
              const expressFee = data.expressFee;
              const payAmount = data.Money
                ? data.Money
                : goodsAmount + expressFee;
             let createTime;
            if(data.modify_date){
              createTime=data.modify_date
            }
            else{
              createTime=data.CreateTime
            }
            createTime =createTime.replace("T", " ");
              // 计算到期时间
              const endTime = this.$util.getOrderEndTime(createTime);
              const mode = {
                createTime: createTime,
                payAmount: payAmount,
                id: data.Id,
                endTime: endTime,
                orderNo: data.OrderId,
                status: data.OrderStatus,
                isDelte: false,
              };
              this.order = mode;
              this.loading = false;
              this.setOrderInfo(mode);
            }
          });
      } else {
        this.loading = false;
      }
    
    },
    startCountdown() {
      this.timer = setInterval(() => {
        const startTime = new Date();
        // 获取时间差
        const diff = this.$util.timeDiff(startTime, this.order.endTime);
        if (diff) {
          if (this.order.orderNo) {
            this.title = "订单：" + this.order.orderNo;
          }

          this.timeRemaining = `${diff[1]}分${diff[2]}秒`;
        } else {
          if (!this.order.isDelte && this.order.status == 0) {
            const query = {
              OrderID: this.order.id,
            };
            this.$axios
              .put("/api/GoodsOrder/CancelOrder", this.qs.stringify(query))
              .then((res) => {
                this.loading = false;
                const data = res.data;
                if (data) {
                  this.removeOrder(this.order);
                }
                this.order.isDelte = true;
                this.closeDialog();
                this.$alert("订单超时", "", {
                  closeOnClickModal: true,
                  callback: () => {
                    location.reload();
                  },
                });
              });
          }

          this.timeRemaining = "";
          this.title = "订单超时";
          this.notifyError("订单超时，自动取消订单！");
          // this.$router.push({ path: `/order/detail?orderNo=${this.orderNo}` });
          clearInterval(this.timer);
        }
      }, 1000);
    },
    requestPayment(mode) {
      this.payMode = mode;
      this.hiddenQrcode = false;
      let logo = mode == 0 ? "alipay-logo" : "wechat-logo";
      this.payLogo = require("@/assets/imgs/pay/" + logo + ".png");
      // this.waitSuccess();
      // uni.showLoading({
      // 	title: '请求中...',
      // 	mask: true
      // });
      // this.$util.request('/order/pay', {
      // 	orderNo: this.order.orderNo,
      // 	payMode: mode
      // }, (res) => {
      // 	uni.hideLoading();
      // 	if (res.state == 'ok') {
      // 		this.qrcodeUrl = encodeURIComponent(res.url);
      // 		this.payMode = mode;
      // 		this.hiddenQrcode = false;
      // 		this.waitSuccess();
      // 	} else {
      // 		this.$alert(res.msg, {
      // 			showCancel: false
      // 		});
      // 	}
      // });
    },
    waitSuccess() {
      // this.wsTask = uni.connectSocket({
      // 	url: `${this.$util.wsUrl}/pay/status.ws/${this.order.orderNo}`,
      // 	complete: () => {}
      // });
      // this.wsTask.onOpen(() => {
      // 	this.wsTask.onMessage((res) => {
      // 		try {
      // 			res = JSON.parse(res.data);
      // 			if (res.state == 'ok') {
      // 				this.cancelPayment();
      // 				uni.navigateTo({
      // 					url: `/pages/order/pay/pay-success?orderNo=${this.order.orderNo}`
      // 				});
      // 			}
      // 		} catch (e) {
      // 			console.error(e);
      // 		}
      // 	});
      // });
    },
    cancelPayment() {
      this.hiddenQrcode = true;

      // 断开ws连接
      //   try {
      //     this.wsTask.close();
      //   } catch (e) {
      //     console.error(e);
      //   }
    },
  },
};
</script>

<style lang="scss">
.order-wrapper {
  background-color: $bg-color-grey;
  // padding-top: 38px;
  // padding-bottom: 30px;

  .wrap {
    background-color: $bg-color;
    // width: $page-width;
    margin: 0 auto;
    text-align: center;
    .info-item {
      color: #616161;
      margin-bottom: 15px;
      line-height: 2;

      .duration {
        margin: 0 5px;
        color: $color-primary;
        font-size: 16px;
      }
    }
    .pay-amount {
      margin-bottom: 20px;
      color: #757575;

      .amount {
        font-size: 24px;
        color: $color-primary;
      }

      .unit {
        color: $color-primary;
      }
    }
    .top {
      display: flex;
      // padding: 38px;

      .i-success {
        font-size: 100px;
        color: $color-success;
        margin-right: 48px;
      }

      .order-info {
        flex: 1;
        @extend %flex-align-center;
        justify-content: space-between;

        .left {
          .title {
            margin-bottom: 10px;
            font-size: 24px;
            line-height: 36px;
          }
        }

        .right {
          text-align: right;
        }
      }
    }
  }
}

.payment-wrapper {
  background-color: $bg-color-grey;
  padding-bottom: 38px;

  .wrap {
    width: $page-width;
    margin: 0 auto;
    background-color: $bg-color;

    .tip {
      color: #424242;
      padding: 30px 0;
      margin: 0 48px;
      border-bottom: 1px solid $border-color;
      font-size: 18px;
    }

    .pay-platform {
      padding: 30px 48px;

      .title {
        font-size: 16px;
        font-weight: bold;
        color: #616161;
        margin-bottom: 20px;
      }

      .platform {
        width: 174px;
        height: 60px;
        cursor: pointer;
        margin-right: 14px;
        border: 1px solid $border-color;
        transition: border-color 0.3s;

        &:hover {
          border-color: $color-primary;
        }
      }
    }
  }
}

.qrcode-wrapper {
  width: 100%;

  .qrcode-wrap {
    width: 290px;
    height: 290px;
    position: relative;
    margin: 30px auto 10px;

    .qrcode {
      width: 100%;
      height: 100%;
    }

    .logo-wrap {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 45px;
      height: 45px;
      background-color: $bg-color;
      padding: 3px;
      border-radius: 10px;
      box-shadow: 0 0 5px $bg-color;

      .logo {
        width: 100%;
        height: 100%;
      }
    }
  }

  .tips {
    margin-bottom: 10px;
    // color: $span-color;
    text-align: center;

    .platform {
      color: $color-primary;
      margin: 0 5px;
    }
  }
}
</style>
